/* eslint-disable camelcase */
import format from 'format-util';

const SET_PRESENCE_INTERVAL = 'SET_PRESENCE_INTERVAL';
const TOGGLE_PROFILE_DRAWER = 'TOGGLE_PROFILE_DRAWER';
const WATCH_USER_ID = 'WATCH_USER_ID';
const UNWATCH_USER_ID = 'UNWATCH_USER_ID';

export default () => ({
  actions: {
    async $fetch({ dispatch, rootGetters }, options = {}) {
      const { url, feed, ...filters } = options;
      const _url = url || rootGetters['api/routes'].users;
      const client = rootGetters['api/client'];
      let params = {};
      // Only send params when no URL is provided. The next/previous
      // URIs will already include query parameters. Omit null values.
      if (!url) {
        params = Object.fromEntries(
          Object.entries(filters).filter(([, val]) => val !== null),
        );
      }
      const response = await client.get(_url, { params });
      const { data } = response;
      await dispatch('insert', { data: data.results });

      if (feed) {
        const mapping = data.results.map((user) => ({
          user_feed_id: feed.id,
          user_id: user.id,
        }));
        await dispatch(
          'entities/userFeedUsers/insert',
          { data: mapping },
          { root: true },
        );
      }

      return data;
    },

    async $get({ dispatch, getters, rootGetters }, { username }) {
      const urlTemplate = rootGetters['api/routes'].user;
      const client = rootGetters['api/client'];
      const url = format(urlTemplate, username);
      const response = await client.get(url);
      const { data } = response;
      const payload = await dispatch('insertOrUpdate', { data });
      return getters.query().with('profile').find(payload.users[0].id);
    },

    /**
     * Poll user presence endpoint with currently tracked
     * user IDs.
     */
    async $refreshUserPresence({ dispatch, getters, rootGetters }) {
      const client = rootGetters['api/client'];
      const url = rootGetters['api/routes'].presences;
      const ids = getters.userIdsToWatch;
      if (!ids.length) {
        return;
      }
      const params = { user_ids: ids.join(',') };
      const response = await client.get(url, { params });
      const presentUserIds = response.data.map((user) => user.id);
      dispatch('updatePresences', presentUserIds);
    },

    /**
     * Update users presences.
     */
    updatePresences({ dispatch }, presentUserIds) {
      dispatch('update', {
        where: (user) => user.present && !presentUserIds.includes(user.id),
        data: { present: false },
      });
      dispatch('update', {
        where: (user) => presentUserIds.includes(user.id),
        data: { present: true },
      });
    },

    watchUserId({ commit }, id) {
      return commit(WATCH_USER_ID, id);
    },

    unwatchUserId({ commit }, id) {
      return commit(UNWATCH_USER_ID, id);
    },

    /**
     * Poll to see if currently tracked users are online.
     */
    startPresencePolling({
      commit,
      dispatch,
      rootGetters,
      state,
    }) {
      const { is_authenticated } = rootGetters['auth/currentUser'];
      if (!is_authenticated || state.presenceInterval) {
        return;
      }
      commit(
        SET_PRESENCE_INTERVAL,
        setInterval(() => dispatch('$refreshUserPresence'), 30000),
      );
    },

    stopPresencePolling({ commit, state }) {
      clearInterval(state.presenceInterval);
      commit(SET_PRESENCE_INTERVAL, null);
    },

    /**
     * Toggles the visibility of the user profile drawer
     */
    toggleProfileDrawer: ({ commit }, visible) => {
      commit(TOGGLE_PROFILE_DRAWER, visible);
    },
  },

  getters: {
    profileDrawerVisible: (state) => state.profileDrawerVisible,

    userIdsToWatch: (state) => (
      Object.keys(state.userIdsToWatch).map((id) => parseInt(id, 10))
    ),
  },

  state: {
    presenceInterval: null,
    profileDrawerVisible: false,
    userIdsToWatch: {},
  },

  mutations: {
    [SET_PRESENCE_INTERVAL]: (state, interval) => {
      state.presenceInterval = interval;
    },

    [TOGGLE_PROFILE_DRAWER]: (state, visible) => {
      state.profileDrawerVisible = visible;
    },

    [WATCH_USER_ID]: (state, id) => {
      if (id in state.userIdsToWatch) {
        state.userIdsToWatch[id] += 1;
      } else {
        state.userIdsToWatch[id] = 1;
      }
      state.userIdsToWatch = { ...state.userIdsToWatch }; // Trigger update
    },

    [UNWATCH_USER_ID]: (state, id) => {
      if (state.userIdsToWatch[id] > 1) {
        state.userIdsToWatch[id] -= 1;
      } else {
        delete state.userIdsToWatch[id];
      }
      state.userIdsToWatch = { ...state.userIdsToWatch }; // Trigger update
    },
  },
});
