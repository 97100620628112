import { findLast, sortedUniqBy } from 'lodash-es';

import { NotFoundError, TimeoutError, UserBlockedError } from 'pwa/exceptions';

const SET_LAST_REFRESH = 'SET_LAST_REFRESH';
const SET_VIEWER_STREAM_TYPE = 'SET_VIEWER_STREAM_TYPE';
const STREAM_TYPE_AGORA = 'agora';

export default () => ({
  namespaced: true,

  state: {
    lastRefresh: null,
    viewerStreamType: STREAM_TYPE_AGORA,
    viewerStreamTypeOptions: Object.freeze([
      { id: STREAM_TYPE_AGORA, name: 'Agora' },
    ]),
  },

  mutations: {
    [SET_LAST_REFRESH]: (state, lastRefresh) => {
      state.lastRefresh = lastRefresh;
    },
    [SET_VIEWER_STREAM_TYPE]: (state, streamType) => {
      state.viewerStreamType = streamType;
    },
  },

  getters: {
    allUniqueByUser: (state, getters) => sortedUniqBy(
      getters.query()
        .with('user')
        .orderBy('user.username', 'asc')
        .orderBy('last_active', 'desc')
        .get(),
      (stream) => stream.user.username,
    ),

    lastRefresh: (state) => state.lastRefresh,

    showBanner: (state, getters) => {
      const streams = getters.allUniqueByUser;
      return streams && streams.length;
    },

    activeForUser: (state, getters) => (username) => findLast(
      getters.allUniqueByUser,
      (stream) => stream.user && stream.user.username === username
        && !stream.ended,
    ),

    viewerStreamType: (state) => state.viewerStreamType,
    viewerStreamTypeOptions: (state) => state.viewerStreamTypeOptions,
  },

  actions: {
    async $fetch({ commit, dispatch, rootGetters }, { refresh = false } = {}) {
      const client = rootGetters['api/client'];
      const response = await client.get(rootGetters['api/routes'].live_now);
      if (refresh) {
        await dispatch('deleteAll');
      }
      await dispatch('insert', { data: response.data.results });
      commit(SET_LAST_REFRESH, new Date());
    },

    async $get({ dispatch, getters, rootGetters }, { url }) {
      const client = rootGetters['api/client'];
      const { data } = await client.get(url);
      const stream = await dispatch('insertOrUpdate', { data });
      const streamWithRelations = getters.query().with('user').find(
        stream.streams[0].id,
      );
      return streamWithRelations;
    },

    async $refresh({ dispatch }) {
      await dispatch('$fetch', { refresh: true });
    },

    async streamStartedEvent({ dispatch }, { url }) {
      try {
        await dispatch('$get', { url });
      } catch (e) {
        if (
          e instanceof UserBlockedError
          || e instanceof NotFoundError
          || e instanceof TimeoutError
        ) {
          return;
        }
        throw e;
      }
    },

    async streamEndedEvent({ dispatch }, { id }) {
      await dispatch('delete', id);
    },

    async setViewerStreamType({ commit, state }, streamType) {
      if (state.viewerStreamType !== streamType) {
        await commit(SET_VIEWER_STREAM_TYPE, streamType);
      }
    },
  },
});
