import axios from 'axios';
import Vue from 'vue';

import isTrustedOrigin from 'common/origin';
import {
  APIError,
  InvalidError,
  NetworkError,
  NotAuthenticatedError,
  NotFoundError,
  PaymentRequiredError,
  RequestCancelled,
  ServiceUnavailableError,
  SubscriptionExpiredError,
  TimeoutError,
  UserBlockedError,
} from 'pwa/exceptions';

export const SET_CLIENT = 'SET_CLIENT';
export const SET_ROUTES = 'SET_ROUTES';

export default () => ({
  namespaced: true,

  state: {
    client: null,
    csrfCookieName: null, // Set by Django
    routes: {},
    url: null, // Set by Django
  },

  getters: {
    client: (state) => state.client,

    routes: (state) => state.routes,

    csrfCookieName: (state) => state.csrfCookieName,
  },

  mutations: {
    [SET_CLIENT]: (state, client) => { state.client = client; },
    [SET_ROUTES]: (state, routes) => { state.routes = routes; },
  },

  actions: {
    async $getRoutes({ commit, getters, state }) {
      const response = await getters.client.get(state.url);
      commit(SET_ROUTES, response.data);
    },

    initClient({ commit, getters }) {
      const client = axios.create({
        withCredentials: true,
        xsrfCookieName: getters.csrfCookieName,
        xsrfHeaderName: 'X-CSRFToken',
        timeout: 60000,

        /**
         * Overrides Axios parameter serialization to make
         * arrays send as `param=1&parma=2` instead of
         * the default param[]=1&param[]=2.
         */
        paramsSerializer(params) {
          const searchParams = new URLSearchParams();
          Object.keys(params).forEach((key) => {
            const param = params[key];
            if (Array.isArray(param)) {
              param.forEach((p) => { searchParams.append(key, p); });
            } else {
              searchParams.append(key, param);
            }
          });
          return searchParams.toString();
        },
      });

      client.interceptors.request.use((config) => {
        // Only pass our XSRF token to trusted origins.
        if (isTrustedOrigin(config.url)) {
          config.withXSRFToken = true;
        } else {
          Vue.$log.warn(
            `XSRF token omitted for request to untrusted origin: ${config.url}`,
          );
        }
        return config;
      });

      client.interceptors.response.use(
        (response) => response,
        (error) => {
          if (axios.isCancel(error)) {
            throw new RequestCancelled(error.message);
          }

          let ErrorClass;
          const { response } = error;
          const { data, status } = response || {};
          const { code } = data || {};

          if (!response && error.message === 'Network Error') {
            ErrorClass = NetworkError;
          } else if (!response && error.code === 'ECONNABORTED') {
            ErrorClass = TimeoutError;
          } else if (code === 'not_authenticated') {
            ErrorClass = NotAuthenticatedError;
          } else if (status === 402) {
            ErrorClass = PaymentRequiredError;
          } else if (status === 400 && code === 'invalid') {
            ErrorClass = InvalidError;
          } else if (status === 403 && code === 'user_blocked') {
            ErrorClass = UserBlockedError;
          } else if (status === 403 && code === 'subscription_expired') {
            ErrorClass = SubscriptionExpiredError;
          } else if (status === 404) {
            ErrorClass = NotFoundError;
          } else if (status === 503) {
            ErrorClass = ServiceUnavailableError;
          } else if (response) {
            ErrorClass = APIError;
          }
          if (!ErrorClass) {
            throw error;
          }
          throw new ErrorClass(response);
        },
      );
      return commit(SET_CLIENT, client);
    },
  },
});
