const DISPLAY_DELAY = 500;
const DISPLAY_INTERVAL = 1000;
const DISPLAY_TIMEOUT = 5000;

export default () => ({
  namespaced: true,
  actions: {
    /**
     * Check for flash messages on the server and display if present.
     */
    async checkMessages({ dispatch, rootGetters }) {
      const url = rootGetters['api/routes'].flash_messages;
      const client = rootGetters['api/client'];
      const response = await client.get(url);

      response.data.forEach((message, idx) => {
        const showAtTimeout = ((DISPLAY_TIMEOUT + DISPLAY_INTERVAL) * idx)
          + DISPLAY_DELAY;
        setTimeout(() => {
          dispatch(
            'toast/open',
            {
              html: message.message,
              type: message.level,
              options: {
                timeout: DISPLAY_TIMEOUT,
              },
            },
            {
              root: true,
            },
          );
        }, showAtTimeout);
      });
    },
  },
});
