export default () => ({
  actions: {
    async $create({ getters, dispatch, rootGetters }, payload) {
      const client = rootGetters['api/client'];
      const url = rootGetters['api/routes'].joins;

      const response = await client.post(url, payload);
      const { data } = response;
      await dispatch('insert', { data });

      return getters.find(data.id);
    },

    async $fetch({ dispatch, rootGetters }) {
      const client = rootGetters['api/client'];
      const url = rootGetters['api/routes'].joins;

      const response = await client.get(url);
      const { data } = response;
      await dispatch('insert', { data });
    },
  },
});
