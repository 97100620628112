/* eslint-disable camelcase */
import format from 'format-util';

import { NotFoundError } from 'pwa/exceptions';

export const SET_DEFAULT_PRIVACY = 'SET_DEFAULT_PRIVACY';
export const TRIGGER_RELOAD = 'TRIGGER_RELOAD';

/**
 * Stores post objects and their related content objects into
 * the store.
 */
const storePosts = async ({ posts, dispatch, feed } = {}) => {
  const contentObjects = {}; // Nested content objects
  const postFeedData = []; // M2M association for feeds & posts

  // Maps Django content type model names to Vuex ORM entities
  const contentTypeMap = {
    album: 'albums',
    blogpost: 'blogPosts',
    photoset: 'photosets',
    statusupdate: 'statusUpdates',
    video: 'videos',
  };

  const _posts = [];
  // Iterate over data and create dictionary of entities + data
  posts.forEach((post) => {
    // Skip unrecognized types
    const contentTypeName = post.content_type_name;
    if (!(contentTypeName in contentTypeMap)) {
      return;
    }
    if (feed) {
      postFeedData.push({ post_id: post.id, feed_id: feed.id });
    }
    const entity = contentTypeMap[contentTypeName];
    // Create key in contentObjects if it doesn't exist
    if (!(entity in contentObjects)) {
      contentObjects[entity] = [];
    }
    contentObjects[entity].push(post.content_object);
    _posts.push(post);
  });

  // Insert posts
  await dispatch('insert', { data: _posts });

  // Create associations to feed
  if (feed) {
    await dispatch(
      'entities/postFeeds/insert',
      { data: postFeedData },
      { root: true },
    );
  }

  // Insert nested content type objects
  Object.keys(contentObjects).forEach(async (entity) => {
    await dispatch(
      'entities/insert',
      { entity, data: contentObjects[entity] },
      { root: true },
    );
  });
};

export default () => ({
  state: {
    defaultPrivacy: 'public',
    reloadRequestedAt: undefined,
  },

  mutations: {
    [SET_DEFAULT_PRIVACY]: (state, value) => { state.defaultPrivacy = value; },
    [TRIGGER_RELOAD]: (state) => { state.reloadRequestedAt = new Date(); },
  },

  getters: {
    /**
     * Return the user's preferred privacy value. This value persists
     * in local storage.
     */
    defaultPrivacy: (state, getters) => {
      const privacy = state.defaultPrivacy;
      const index = getters.privacyChoices
        .findIndex(({ value }) => value === privacy);
      if (index === -1) {
        return 'public';
      }
      return privacy;
    },

    /**
     * Returns single post object with related data
     */
    getLoadedObject: (state, getters) => (id) => (
      getters.query().with('users_about').find(id)
    ),

    /**
     * The post list URL
     */
    listUrl: (state, getters, rootState, rootGetters) => (
      rootGetters['api/routes'].posts
    ),

    /**
     * List of privacy definitions
     */
    privacyChoices: (state, getters) => ([
      {
        value: 'public',
        label: 'Public',
        description: 'Anyone on or off SG',
        icon: getters.privacyIcons.public,
      },
      {
        value: 'members',
        label: 'Members',
        description: 'All members of SG',
        icon: getters.privacyIcons.members,
      },
      {
        value: 'followers',
        label: 'Followers',
        description: 'Your followers on SG',
        icon: getters.privacyIcons.followers,
      },
      {
        value: 'private',
        label: 'Only Me',
        description: 'Only me',
        icon: getters.privacyIcons.private,
      },
    ]),

    privacyIcons: () => ({
      public: 'mdi-earth',
      members: 'mdi-account-multiple',
      followers: 'mdi-account-check',
      private: 'mdi-lock',
    }),

    reloadRequestedAt: (state) => state.reloadRequestedAt,
  },

  actions: {
    /**
     * Retrieves and stores a list of posts from the API
     */
    async $fetch(
      { dispatch, getters, rootGetters },
      { feed, url, ...filters } = {},
    ) {
      const params = {};
      // Only send params when no URL is provided. The next/previous
      // URIs will already include query parameters. Prevent null/undefined
      // values from being sent to API.
      if (!url) {
        Object.entries(filters).forEach(([key, val]) => {
          if (val == null) return;
          params[key] = val;
        });
      }

      const _url = url || getters.listUrl;
      const client = rootGetters['api/client'];

      const response = await client.get(_url, { params });
      const { data } = response;
      await storePosts({ posts: data.results, dispatch, feed });
      return data;
    },

    /**
     * Marks the state as having triggered a feed reload
     */
    reload({ commit }) {
      commit(TRIGGER_RELOAD);
    },

    /**
     * Gets and stores single post object
     */
    async $get(
      { dispatch, getters, rootGetters },
      { url = null, id = null } = {},
    ) {
      const client = rootGetters['api/client'];
      let _url = url;
      if (id) {
        _url = format(rootGetters['api/routes'].post, id);
      }
      const { data } = await client.get(_url);
      await storePosts({ posts: [data], dispatch });
      return getters.getLoadedObject(data.id);
    },

    /**
     * Retrieves post object using the content_type and object_id
     * of the contentObject (e.g. album, etc.)
     */
    async $getFromContentObject(
      { dispatch, getters, rootGetters },
      { content_type, object_id },
    ) {
      const client = rootGetters['api/client'];
      const url = rootGetters['api/routes'].posts;
      const params = { content_type, object_id };
      const response = await client.get(url, { params });
      const { results } = response.data;
      if (!results.length) {
        throw new NotFoundError(response);
      }
      await storePosts({ posts: results.slice(0), dispatch });
      return getters.getLoadedObject(results[0].id);
    },

    async $create(
      { rootGetters },
      {
        images = [],
        privacy,
        text = '',
        video = null,
      },
    ) {
      const client = rootGetters['api/client'];
      const url = rootGetters['api/routes'].posts;
      const data = {
        images,
        privacy,
        text,
        video,
      };
      return client.post(url, data);
    },

    setDefaultPrivacy: async ({ commit }, value) => commit(
      SET_DEFAULT_PRIVACY,
      value,
    ),
  },
});
