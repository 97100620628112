import format from 'format-util';

export const TRIGGER_RELOAD = 'TRIGGER_RELOAD';
export const SET_NEXT = 'SET_NEXT';
export const SET_PREVIOUS = 'SET_PREVIOUS';
export const SET_UNREAD_COUNT = 'SET_UNREAD_COUNT';
export const ACKNOWLEDGE_UNREAD = 'ACKNOWLEDGE_UNREAD';

export default () => ({
  state: {
    next: undefined,
    previous: undefined,
    unreadAcknowledged: false,
    unreadCount: 0,
  },

  mutations: {
    [SET_NEXT]: (state, url) => { state.next = url; },
    [SET_PREVIOUS]: (state, url) => { state.previous = url; },
    [SET_UNREAD_COUNT]: (state, value) => {
      state.unreadAcknowledged = (
        (value === state.unreadCount) && state.unreadAcknowledged
      );
      state.unreadCount = value;
    },
    [ACKNOWLEDGE_UNREAD]: (state) => { state.unreadAcknowledged = true; },
  },

  getters: {
    listUrl: (state, getters, rootState, rootGetters) => (
      rootGetters['api/routes'].conversations
    ),

    next: (state, getters) => (
      state.next ? state.next : getters.listUrl
    ),

    previous: (state, getters) => (
      state.previous ? state.previous : getters.listUrl
    ),

    unreadAcknowledged: (state) => state.unreadAcknowledged,

    unreadCount: (state) => state.unreadCount,
  },

  actions: {
    /**
     * Retrieves list of conversations
     *
     * @param {Object} options
     * @param {string} options.url=this.listUrl - The endpoint url
     * @param {boolean} options.reload=false - If true, replace the store
     */
    async $fetch(
      {
        commit,
        dispatch,
        getters,
        rootGetters,
      },
      {
        url,
        reload = false,
      } = {},
    ) {
      const _url = url || getters.listUrl;
      const client = rootGetters['api/client'];

      const response = await client.get(_url);
      const { data } = response;
      if (reload) {
        await dispatch('deleteAll');
      }

      await dispatch('insertOrUpdate', { data: data.results });
      commit(SET_UNREAD_COUNT, data.unread_count);
      return data;
    },

    async $fetchNext({ commit, dispatch, getters }) {
      const data = await dispatch('$fetch', { url: getters.next });
      commit(SET_NEXT, data.next);
      return data;
    },

    async $fetchPrevious({ commit, dispatch, getters }) {
      const data = await dispatch('$fetch', { url: getters.previous });
      commit(SET_PREVIOUS, data.previous);
      return data;
    },

    async $get({ dispatch, rootGetters }, { id }) {
      const client = rootGetters['api/client'];
      const url = format(rootGetters['api/routes'].conversation, id);
      const { data } = await client.get(url);
      const conversation = await dispatch('insert', { data });
      return conversation.conversations[0];
    },

    async $create({ dispatch, getters, rootGetters }, { participants }) {
      const client = rootGetters['api/client'];
      const url = getters.listUrl;
      const { data } = await client.post(url, { participants });
      const conversation = await dispatch('insert', { data });
      return conversation.conversations[0];
    },

    async userTyping({ getters }, payload) {
      const { id } = payload.conversation;
      const conversation = getters.find(id);
      if (!conversation) {
        return null;
      }
      return conversation.receiveTypingEvent();
    },

    /**
     * Call to indicate that the user has taken an action
     * to acknowledge they have seen the notification icon
     */
    acknowledgeUnreadNotifications({ commit }) {
      commit(ACKNOWLEDGE_UNREAD);
    },

    incrementUnreadCount({ commit, getters }) {
      commit(SET_UNREAD_COUNT, getters.unreadCount + 1);
    },

    decrementUnreadCount({ commit, getters }) {
      commit(SET_UNREAD_COUNT, getters.unreadCount - 1);
    },

    setUnreadCount({ commit }, value) {
      commit(SET_UNREAD_COUNT, value);
    },
  },
});
