import { Container } from '@vuex-orm/core';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const TOGGLE_MEMBERS_ONLY_SHEET = 'TOGGLE_MEMBERS_ONLY_SHEET';
export const TOGGLE_RENEW_SHEET = 'TOGGLE_RENEW_SHEET';

export default () => ({
  namespaced: true,

  state: {
    currentUser: null,
    displayMembersOnlySheet: false,
    displayRenewSubscriptionSheet: false,
  },

  actions: {
    /**
     * Ensures user is authenticated and enabled before executing
     * optional callback function. If condition fails, display
     * either the members-only sheet or the subscription expired
     * sheets.
     */
    async enforceUserEnabled({ dispatch, getters }, callback) {
      const user = getters.currentUser;
      if (!user.is_authenticated) {
        return dispatch('toggleMembersOnlySheet', true);
      }
      if (user.status !== 'enabled') {
        return dispatch('toggleRenewSubscriptionSheet', true);
      }
      return callback ? callback() : null;
    },

    /**
     * Toggles the visibility of TheMembersOnlySheet component
     */
    toggleMembersOnlySheet: ({ commit, getters }, value) => {
      const _value = (
        value !== undefined
          ? value : !getters.displayMembersOnlySheet
      );
      commit(TOGGLE_MEMBERS_ONLY_SHEET, _value);
    },

    /**
     * Toggles the visibility of TheRenewSubscriptionSheet component
     */
    toggleRenewSubscriptionSheet: ({ commit, getters }, value) => {
      const _value = (
        value !== undefined
          ? value : !getters.displayRenewSubscriptionSheet
      );
      commit(TOGGLE_RENEW_SHEET, _value);
    },

    /**
     * Authenticates user using provided username & password.
     */
    $login: async ({ rootGetters }, { username, password }) => {
      const client = rootGetters['api/client'];
      const url = rootGetters['api/routes'].login;
      return client.post(url, { username, password });
    },

    $logout: async ({ dispatch, rootGetters }) => {
      const client = rootGetters['api/client'];
      const url = rootGetters['api/routes'].logout;
      await dispatch(
        'loadingState/activate',
        client.post(url),
        { root: true },
      );
      await dispatch('pusher/stopBeams', null, { root: true });
      window.location.replace('/');
    },

    $getCurrentUser: async ({ dispatch, rootGetters }) => {
      const client = rootGetters['api/client'];
      const url = rootGetters['api/routes'].current_user;
      if (!url) {
        return null;
      }
      const response = await client.get(url);
      const { data } = response;
      const User = Container.store.$db().model('users');
      await dispatch('setCurrentUser', new User(data));
      return data;
    },

    /**
     * Sets the provided User object as the currently
     * logged in user.
     */
    setCurrentUser: async ({ commit }, user) => {
      commit(SET_CURRENT_USER, user);
    },
  },

  getters: {
    /**
     * Returns current user object
     */
    currentUser: (state) => {
      const User = Container.store.$db().model('users');
      const { currentUser } = state;
      return currentUser || new User({ is_authenticated: false });
    },

    displayMembersOnlySheet: (state) => state.displayMembersOnlySheet,

    displayRenewSubscriptionSheet: (state) => (
      state.displayRenewSubscriptionSheet
    ),
  },

  mutations: {
    [SET_CURRENT_USER]: (state, user) => {
      state.currentUser = user;
    },

    [TOGGLE_MEMBERS_ONLY_SHEET]: (state, value) => {
      state.displayMembersOnlySheet = value;
    },

    [TOGGLE_RENEW_SHEET]: (state, value) => {
      state.displayRenewSubscriptionSheet = value;
    },
  },
});
