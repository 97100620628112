const SET_RECIPIENTS = 'SET_RECIPIENTS';
const SET_TIPS_URL = 'SET_TIPS_URL';
const TIPPING_DIALOG_VISIBILITY = 'TIPPING_DIALOG_VISIBILITY';

export default () => ({
  namespaced: true,

  state: {
    balance: 0,
    showTippingDialog: false,
    recipients: null,
    tipsURL: null,
  },

  mutations: {
    [SET_RECIPIENTS]: (state, recipients) => {
      state.recipients = recipients;
    },

    [SET_TIPS_URL]: (state, tipsURL) => {
      state.tipsURL = tipsURL;
    },

    [TIPPING_DIALOG_VISIBILITY]: (state, visibility) => {
      state.showTippingDialog = visibility;
    },
  },

  getters: {
    /**
     * Returns true if the tipping dialog should be visible
     */
    showTippingDialog: (state) => state.showTippingDialog,

    /**
      Returns objects containing usernames of recipients who would share
      the tip sent from the dialog.
     */
    recipients: (state) => state.recipients,

    /**
      Returns the URL for sending the tip request for the current open
      tipping dialog.
    */
    tipsURL: (state) => state.tipsURL,
  },

  actions: {
    openTippingDialog: ({ commit, dispatch }, { recipients, tipsURL }) => {
      commit(TIPPING_DIALOG_VISIBILITY, true);
      commit(SET_RECIPIENTS, recipients);
      commit(SET_TIPS_URL, tipsURL);
      dispatch('auth/$getCurrentUser', null, { root: true });
    },

    closeTippingDialog: ({ commit }) => {
      commit(TIPPING_DIALOG_VISIBILITY, false);
      commit(SET_RECIPIENTS, null);
    },

    tip: async ({ dispatch, getters, rootGetters }, tipData) => {
      const client = rootGetters['api/client'];
      const response = await client.post(getters.tipsURL, tipData);
      dispatch('auth/$getCurrentUser', null, { root: true }); // Refresh balance
      return response;
    },
  },
});
