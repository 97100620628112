const OPEN_GALLERY = 'OPEN_GALLERY';
const CLOSE_GALLERY = 'CLOSE_GALLERY';

export default () => ({
  namespaced: true,

  state: {
    images: null,
    index: null,
  },

  getters: {
    images: (state) => state.images,
    index: (state) => state.index,
    open: (state) => state.index !== null,
  },

  mutations: {
    [OPEN_GALLERY]: (state, { images, index }) => {
      state.images = images;
      state.index = index;
    },

    [CLOSE_GALLERY]: (state) => {
      state.images = null;
      state.index = null;
    },
  },

  actions: {
    open: ({ commit, dispatch }, { images = null, index = 0 } = {}) => {
      commit(OPEN_GALLERY, { images, index });
      dispatch('browser/lockScrolling', null, { root: true });
    },

    close: ({ commit, dispatch }) => {
      commit(CLOSE_GALLERY);
      dispatch('browser/unlockScrolling', null, { root: true });
    },
  },
});
