import { isEqual } from 'lodash-es';

const REQUEST_RELOAD = 'REQUEST_RELOAD';
const SET_ORDERING_FILTER = 'SET_ORDERING_FILTER';
const SET_SOTD_USER_TYPE_FILTERS = 'SET_SOTD_USER_TYPE_FILTERS';
const SET_USER_TYPE_FILTERS = 'SET_USER_TYPE_FILTERS';

/**
 * Return true if field matches comparison value.
 * Treat arrays as unordered sets.
 */
const setOrStringEquals = (value1, value2) => {
  if (value1 instanceof Array && value2 instanceof Array) {
    return isEqual([...value1].sort(), [...value2].sort());
  }
  return value1 === value2;
};

export default () => ({
  getters: {
    // List of supported API filters
    apiFilters: () => ([
      'created_after',
      'ordering',
      'type',
      'url',
      'user_type',
      'username',
      'photographer_username',
    ]),

    selectedOrderingFilter: (state) => state.selectedOrderingFilter,

    selectedSotdUserTypeFilters: (state, getters) => {
      const filters = (
        state.selectedSotdUserTypeFilters
        || getters.sotdUserTypeFilters
      );
      return filters.sort();
    },

    selectedUserTypeFilters: (state, getters) => {
      const filters = state.selectedUserTypeFilters || getters.userTypeFilters;
      return filters.sort();
    },

    reloadRequestedAt: (state) => state.reloadRequestedAt,

    sotdUserTypeFilters: (state, getters, rootState, rootGetters) => {
      const filters = ['sgs'];
      if (rootGetters['auth/currentUser'].is_authenticated) {
        filters.push('following');
      }
      return filters;
    },

    userTypeFilters: (state, getters) => {
      const filters = getters.sotdUserTypeFilters;
      return ['hopefuls', ...filters];
    },
  },

  actions: {
    /**
     * Gets or creates a new feed instance based on the
     * filters provided.
     */
    async getOrCreate({ dispatch, getters }, filters = {}) {
      const feed = getters.query().where((_feed) => {
        const matches = getters.apiFilters.map((filter) => setOrStringEquals(
          _feed[filter],
          filters[filter] || null,
        ));
        return matches.indexOf(false) === -1;
      }).first();

      if (feed) {
        return feed;
      }

      if (!Object.keys(filters).length) {
        return dispatch('new');
      }
      const result = await dispatch('insert', { data: filters });
      return result.feeds[0];
    },

    /**
     * Creates a fresh feed instance with latest data
     */
    async reload({ dispatch, getters }, feed) {
      const data = Object.fromEntries(
        getters.apiFilters.map((filter) => [filter, feed[filter]]),
      );

      const { feeds } = await dispatch('insert', { data });
      const [newFeed] = feeds;
      const promise = newFeed.$fetchPosts();
      const delay = new Promise((r) => setTimeout(r, 1000));
      await Promise.all([promise, delay]);
      await feed.$delete();
      return newFeed;
    },

    /**
     * Triggers the reload/replacement of active feeds
     */
    async requestReload({ commit }) {
      await commit(REQUEST_RELOAD);
    },

    async setOrderingFilter({ commit }, value) {
      await commit(SET_ORDERING_FILTER, value);
    },

    async setSotdUserTypeFilters({ commit }, value) {
      await commit(SET_SOTD_USER_TYPE_FILTERS, value);
    },

    async setUserTypeFilters({ commit }, filters) {
      await commit(SET_USER_TYPE_FILTERS, filters);
    },
  },

  mutations: {
    [REQUEST_RELOAD]: (state) => {
      state.reloadRequestedAt = new Date();
    },

    [SET_ORDERING_FILTER]: (state, value) => {
      state.selectedOrderingFilter = value;
    },

    [SET_SOTD_USER_TYPE_FILTERS]: (state, filters) => {
      state.selectedSotdUserTypeFilters = filters;
    },

    [SET_USER_TYPE_FILTERS]: (state, filters) => {
      state.selectedUserTypeFilters = filters;
    },
  },

  state: {
    reloadRequestedAt: null,
    selectedOrderingFilter: 'latest',
    selectedSotdUserTypeFilters: null,
    selectedUserTypeFilters: null,
  },
});
