import Url from 'url-parse';

const SET_UNREAD_COUNT = 'SET_UNREAD_COUNT';
const ACKNOWLEDGE_UNREAD = 'ACKNOWLEDGE_UNREAD';

export default () => ({
  state: {
    unreadAcknowledged: false,
    unreadCount: 0,
  },

  actions: {
    async $fetch(
      { commit, dispatch, rootGetters },
      { url, type = null } = {},
    ) {
      const client = rootGetters['api/client'];
      const routes = rootGetters['api/routes'];
      const _url = url || routes.notifications;

      // Overwrite the "type" query parameter and ensure only
      // a single value is sent
      const params = { ...Url(_url, true).query };
      if (type) {
        params.type = type;
      }

      const { data } = await client.get(_url, { params });
      await dispatch('insertOrUpdate', { data: data.results });

      if (!type) {
        // Only set the unread count when the results aren't
        // filtered.
        commit(SET_UNREAD_COUNT, data.unread_count);
      }
      return data;
    },

    async $clearNotifications({ commit, rootGetters }) {
      const client = rootGetters['api/client'];
      const routes = rootGetters['api/routes'];
      await client.post(routes.clear_notifications);
      commit(SET_UNREAD_COUNT, 0);
    },

    acknowledgeUnread({ commit }) {
      commit(ACKNOWLEDGE_UNREAD);
    },

    newNotificationEvent({ dispatch }, data) {
      dispatch('insertOrUpdate', { data });
      dispatch('incrementUnreadCount');
    },

    incrementUnreadCount({ commit, getters }) {
      const count = getters.unreadCount + 1;
      commit(SET_UNREAD_COUNT, count);
    },
  },

  getters: {
    unreadAcknowledged: (state) => state.unreadAcknowledged,
    unreadCount: (state) => state.unreadCount,
  },

  mutations: {
    [ACKNOWLEDGE_UNREAD]: (state) => { state.unreadAcknowledged = true; },
    [SET_UNREAD_COUNT]: (state, value) => {
      if (value > state.unreadCount) {
        state.unreadAcknowledged = false;
      }
      state.unreadCount = value;
    },
  },
});
