export default () => ({
  actions: {
    async $fetch({ dispatch, rootGetters }, specialOffer) {
      const client = rootGetters['api/client'];
      const url = rootGetters['api/routes'].plans;

      const params = {};
      if (specialOffer) {
        params.special_offer = specialOffer;
      }
      const response = await client.get(url, { params });

      // Set special offer code where appropriate for internal use
      const data = response.data.map((plan) => ({
        ...plan,
        specialOfferCode: plan.special_offer ? specialOffer : null,
      }));
      // Delete all existing records on fetch
      await dispatch('create', { data });
    },
  },
});
