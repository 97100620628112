export default () => ({
  actions: {
    async $fetch({ dispatch, rootGetters }, { url }) {
      const client = rootGetters['api/client'];

      const response = await client.get(url);
      const { data } = response;
      await dispatch('insertOrUpdate', { data: data.results });
      return data;
    },

    async $create({ dispatch, getters, rootGetters }, { conversation, text }) {
      const client = rootGetters['api/client'];
      const url = rootGetters['api/routes'].messages;
      const response = await client.post(url, { conversation, text });
      await dispatch('insert', { data: response.data });
      return getters.find(response.data.id);
    },

    async $get({ dispatch, rootGetters }, { url }) {
      const client = rootGetters['api/client'];

      const response = await client.get(url);
      const { data } = response;
      await dispatch('insertOrUpdate', { data });
      return data;
    },

    /**
     * Handles the `new_message` event by inserting or updating
     * new message & conversation objects to the store
     */
    async newMessageEvent(
      { dispatch, rootGetters },
      { conversation, message },
    ) {
      const { id } = conversation;
      const existing = rootGetters['entities/conversations/find'](id);
      const markUnread = existing ? existing.read : true;
      const payload = await dispatch(
        'entities/conversations/insertOrUpdate',
        { data: conversation },
        { root: true },
      );
      const [_conversation] = payload.conversations;
      await _conversation.clearTypingState();

      if (message.truncated) {
        await dispatch('$get', { url: message.url });
      } else {
        await dispatch('insertOrUpdate', { data: message });
      }

      if (markUnread) {
        await dispatch(
          'entities/conversations/incrementUnreadCount',
          null,
          { root: true },
        );
      }
    },
  },
});
