import { UserFeed } from 'pwa/models';

export default () => ({
  actions: {
    /**
     * Gets or creates a new feed instance based on the
     * filters provided.
     */
    async getOrCreate({ dispatch, getters }, filters = {}) {
      // Return any existing feed that matches all filters
      let feed = getters.query().where(
        (_feed) => !(
          Object.entries(filters).some(([key, val]) => _feed[key] !== val)
        ),
      ).first();

      if (feed) {
        return feed;
      }

      feed = new UserFeed(filters);
      const { userFeeds } = await dispatch('insert', { data: feed });
      return userFeeds[0];
    },
  },
});
