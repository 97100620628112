export default () => ({
  actions: {
    /**
     * Gets comment list and returns the response data
     */
    async $fetch({ dispatch, rootGetters }, { obj, url }) {
      const client = rootGetters['api/client'];
      const { data } = await client.get(url);
      const comments = data.results.map(
        (comment) => ({
          ...comment,
          ...{
            commentable_id: obj.id,
            commentable_type: obj.constructor.entity,
          },
        }),
      );
      await dispatch('insertOrUpdate', { data: comments });
      return data;
    },

    async $create({ dispatch, rootGetters }, { url, text, commentableObj }) {
      const client = rootGetters['api/client'];
      const { data } = await client.post(url, { text });
      data.commentable_id = commentableObj.id;
      data.commentable_type = commentableObj.constructor.entity;
      await dispatch('insertOrUpdate', { data });
      commentableObj.comment_count += 1;
      return data;
    },
  },
});
