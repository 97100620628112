export default () => ({
  actions: {
    async $fetchUsersSimilarTo({ dispatch, rootGetters }, user) {
      const { username } = user;
      const client = rootGetters['api/client'];
      const url = rootGetters['api/routes'].recommendations;
      const params = { username, following: false };
      const response = await client.get(url, { params });
      const { data } = response;

      await dispatch('insertOrUpdate', { data });
    },
  },

  getters: {
    filterByUser: (state, getters) => (user) => (
      getters.query().where('user_id', user.id).withAll().all()
    ),
  },
});
